import Head from 'next/head';
import FormContainer from 'components/Form/FormContainer';
export default function Login(){
    return (
        <div className="bg-grey-omc h-screen">
            <Head>
                <link rel="icon" href="/logo/icon.svg"/>
                <title>OMC International :: Login</title> 
            </Head>
            <FormContainer/>
        </div>
    )
}
