import { Auth } from 'aws-amplify';
import axios from 'axios';


/**
 * @description, Resets user password to 1234test incase he's a new user, only
 *  used for testing, as OMC users are expected to be already registered.
 * 
 * @param {The user's email} email
 * @param {The user's current temporary password(can be found on the user's email)} password 
 */

export async function authenicateAndReset(email, password){
    
    await Auth.signIn(email, password).then(async (user)=>{
        if (user["challengeName"] === "NEW_PASSWORD_REQUIRED"){
            const { requiredAttributes } = user.challengeParam;
            await Auth.completeNewPassword(
                user,
                "1234test",
            ).
            then(user => {
                
            }).catch(error => {
                
            });
        } 
    }).then(()=>{
        
    }).catch(exception=>{
        
        
    });
};

/**
 * @description Decodes the JWT on the server, if the token is decoded successfully
 * a rails_token and a rails_token_received key-pair is stored in localStorage.
 * 
 * @param {Link to server login endpoint} URL 
 * @param {setState to the responded variable, triggered when positive response is received from server} setToHasResponded 
 * @param {The JWT token that was received from Cognito} currentJwtToken 
 */
export async function decodeJWTTokenInServer(URL, postLoginTransition, cognitoJWT){
    await axios.get(URL, {
        headers : {
            'Content-Type':'application/json',
            Authorization: "Bearer " + cognitoJWT
        }
    }).then(response => {
        // 
        // 
        localStorage.setItem('rails_token',JSON.stringify(response));
        postLoginTransition(response["data"]["last_admin_path"], response["data"]["last_path"]);
    });
};

// checks if the token expired
const isExpiredJWT = (exp) => {

  let now = new Date()
  const expiry = new Date(exp * 1000)

  now = new Date(now.getTime() + 5 * 60000)

  // console.log("TTL", now, expiry, expiry < now)
  if (expiry < now) {
    return true
  }

  return false 
}

// refreshes the jwt from local storage
const refreshToken = async (jwt) => {

  return new Promise( async (resolve, reject) => {

    let token = jwt

    try {
      let cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        const idToken = session.idToken.jwtToken;
         
        const new_token = parseJwt(idToken)

        token["data"]["jwt"] = idToken
        token["data"]["expiry"] = new_token.exp

        localStorage.setItem('rails_token',JSON.stringify(token));

        resolve(idToken)

      });
    } catch (e) {
      reject(e);
    }

  })
}

export const updateJWT = () => {

  let cognitoJWT =  JSON.parse(window.localStorage.getItem('rails_token'))
  let token = null

  if (cognitoJWT && cognitoJWT["data"])
  {
    let old_token = cognitoJWT["data"]["jwt"]
    if(isExpiredJWT(cognitoJWT["data"]["expiry"])) {
      refreshToken(cognitoJWT)
      token = JSON.parse(window.localStorage.getItem('rails_token'))['data']['jwt']
    } else {
      token = cognitoJWT["data"]["jwt"]
    }
  }
  return token
}

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

